/**
 * Created by spepa on 25.05.21.
 */

var MineGameScene = cleverapps.FixedWidthScene.extend({
    ctor: function (options) {
        this.options = options;

        this._super();
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_MINE);

        this.mineGame = new MineSweeper(this.options);

        this.createGameNode();
        this.createGuideButton();
        this.createPerson();

        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MINESWEEPER_START);
    },

    createGuideButton: function () {
        var guideButton = new cleverapps.UI.HelpButton({
            callback: function () {
                cleverapps.focusManager.display({
                    focus: "GuideMine",
                    action: function (f) {
                        new GuideWindow(MineGuideOptions);
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }
                });
            } 
        });

        var styles = cleverapps.styles.MineGameScene.guideButton;
        guideButton.setPositionRound(styles);
        this.addChild(guideButton);

        this.guideButton = new HidingNode(guideButton, cleverapps.UI.VERTICAL);
        cleverapps.focusManager.registerControl("guideButton", this.guideButton);
    },

    createGameNode: function () {
        var styles = cleverapps.styles.MineGameScene;

        this.gameNode = new MineSweeperNode(this.mineGame);
        this.addChild(this.gameNode);
        this.gameNode.setPositionRound(styles.minigame);

        this.mineGame.on("win", this.gameFinish.bind(this), this);
        this.mineGame.on("lose", this.onLose.bind(this), this);
        this.mineGame.on("victory", this.onVictory.bind(this), this);
        this.mineGame.on("askContinue", this.askContinue.bind(this), this);
        this.mineGame.on("open", this.onCellOpen.bind(this), this);
        this.mineGame.on("resumed", this.onResumed.bind(this), this);
        this.mineGame.on("bomb", this.onBomb.bind(this), this);
    },

    onVictory: function () {
        this.person.runAction(new cc.Sequence(
            new cc.MoveBy(0.2, cc.p(0, -this.person.height * this.person.scale)),
            new cc.RemoveSelf()
        ));
    },

    onLose: function (f) {
        new MineVictoryWindow({
            reward: this.mineGame.reward,
            lose: true
        });
        cleverapps.focusManager.onceNoWindowsListener = f;
    },

    askContinue: function (options) {
        var isContinued = false;
        cleverapps.focusManager.display({
            focus: "loseMine",
            actions: [
                function (f) {
                    cleverapps.timeouts.setTimeout(f, 500);
                },
                function (f) {
                    new MineContinueWindow({
                        reward: this.mineGame.reward,
                        callback: function () {
                            isContinued = true;
                        }
                    });
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }.bind(this),

                function (f) {
                    if (isContinued) {
                        this.mineGame.resume();
                        f();
                    } else {
                        this.mineGame.lose(f, options);
                    }
                }.bind(this)
            ]
        });
    },

    gameFinish: function () {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.MINESWEEPER_VICTORY);
        cleverapps.audio.playSound(bundles.minesweeper.urls.victory_sfx);
        this.person.setAnimation(0, "happy", true);

        cleverapps.focusManager.display({
            focus: "VictoryMine",
            actions: [
                function (f) {
                    cleverapps.timeouts.setTimeout(f, 1000);
                },

                function (f) {
                    new MineVictoryWindow({
                        reward: this.mineGame.reward
                    });
                    cleverapps.focusManager.onceNoWindowsListener = f;
                }.bind(this)
            ]
        });
    },

    getBackgroundStyles: function () {
        return {
            bundle: "minesweeper",
            backgroundId: "background",
            animation: bundles.minesweeper.jsons.bg_json
        };
    },

    getAudioStyles: function () {
        return {
            res: bundles.minesweeper.urls.mine_music,
            delay: 1.0
        };
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }

        this._super();

        var styles = cleverapps.styles.MineGameScene;
        this.gameNode.setPositionRound(styles.minigame);
        this.guideButton.setPositionRound(styles.guideButton);

        this.person.setVisible(cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL);
        this.person.setPositionRound(styles.person);
    },

    createPerson: function () {
        var styles = cleverapps.styles.MineGameScene.person;
        var person = this.person = new Person("miner");
        person.setPositionRound(styles);
        person.setLocalZOrder(1);
        this.addChild(person);
        person.setVisible(cleverapps.resolution.mode === cleverapps.WideMode.HORIZONTAL);
    },

    onCellOpen: function (options) {
        if (this.person.isVisible()) {
            this.person.setAnimationAndIdleAfter(options.bomb ? "yank" : "happy", "idle");
        }
    },

    onResumed: function () {
        this.person.setAnimationAndIdleAfter("happy", "idle");
        cleverapps.audio.playSound(bundles.minesweeper.urls.buy_continue_sfx);
        cleverapps.audio.playSound(bundles.minesweeper.urls.miner_good_sfx);
    },

    onBomb: function () {
        this.runAction(new cc.Sequence(
            new cc.DelayTime(1),
            new cc.CallFunc(function () {
                this.person.setAnimationAndIdleAfter("explosion", "sad");
                cleverapps.audio.playSound(bundles.minesweeper.urls.bomb_sfx);
            }.bind(this))
        ));
    },

    _closeAction: function () {
        var shouldExit = false;

        cleverapps.focusManager.display({
            focus: "ConfirmMineExit",
            actions: [
                function (f) {
                    new ConfirmExitWindow({
                        text: "ConfirmExitWindow.text",
                        reward: Object.assign({
                            hard: 0,
                            soft: 0,
                            energy: 0
                        }, this.mineGame.reward),
                        action: function () {
                            shouldExit = true;
                        }
                    });

                    cleverapps.focusManager.onceNoWindowsListener = f;
                }.bind(this),

                function (f) {
                    if (!shouldExit) {
                        f();
                        return;
                    }

                    if (!this.mineGame.reward) {
                        this.mineGame.saver.add({
                            reward: { canceled: true }
                        });
                    }
                    f();
                    cleverapps.FixedWidthScene.prototype._closeAction.call(this);
                }.bind(this)
            ]
        });
    },

    listBundles: function () {
        return ["minesweeper"];
    }
});

cleverapps.styles.MineGameScene = {
    minigame: {
        x: { align: "center" },
        y: { align: "center", dy: -10 }
    },

    guideButton: {
        x: { align: "left", dx: 65 },
        y: { align: "top", dy: -30 }
    },

    person: {
        x: { align: "center", dx: -850 },
        y: { align: "bottom", dy: -170 }
    }
};